
import { defineComponent, onMounted } from "vue";
import ClientList from "@/components/reusable/clients/ClientList.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";

export default defineComponent({
  name: "broker-client-listing",
  components: { ClientList },
  data() {
    return {
      clients: [],
    };
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Clients List");
      MenuComponent.reinitialization();
    });
  },
  created() {
    this.getClientsList();

    //Set page title
    document.title = "Clients List | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    getClientsList() {
      //Fetch list of client
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.BROKER_CLIENTS_LIST_ROUTE)
          .then(({ data }) => {
            //Assign data to props
            this.clients = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
  },
});
